<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>Controle de acessos </v-card-title>
        <v-card-text>Sprint 1.</v-card-text>
        <v-card-text>
          <perfect-scrollbar class="ps-nav-menu-items">
            Gerar controle de acesso entre usuários e permissões
          </perfect-scrollbar>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import store from "@/store";
  import {
    onUnmounted,
    onMounted,
    ref,
    reactive,
    computed,
    nextTick,
    watch,
    onUpdated,
  } from "@vue/composition-api";
  import { PerfectScrollbar } from "vue2-perfect-scrollbar";
  
  export default {
    name: "Controle-Acesso",
    components: {
      // 3rd Party
      PerfectScrollbar,
    },
    setup() {
      onMounted(() => {});
      return {
        // 3rd Party
        PerfectScrollbar,
      };
    },
  };
  </script>
  
  <style>
  </style>